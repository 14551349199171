.oferta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
    .oferta-container-volver {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: auto;
        cursor: pointer;
        margin-bottom: 5px;
    }
        .oferta-container-volver-text {
            color: var(--primary500);
        }
    .oferta-container-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
    }
        .oferta-container-main-emptyList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 50px;
        }
            .oferta-container-main-emptyList-text {
                text-align: center;
                color: var(--gray400);
            }
            .oferta-container-main-emptyList-errorText {
                color: var(--gray700);
                margin-top: 20px;
            }
        .oferta-container-main-insurers {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 8px;
            width: 100%;
        }
            .oferta-container-main-insurers-title {
                color: var(--gray700);
                margin-bottom: 21px;
            }
            .oferta-container-main-insurers-list {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
                .oferta-container-main-insurers-list-item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 13.125px;
                    background: var(--white);
                    width: 140px;
                    height: 70px;
                    cursor: pointer;
                    margin-right: 15px;
                }
                    .oferta-container-main-insurers-list-item-img {
                    }
        .oferta-container-main-product {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-right: auto;
        }
            .oferta-container-main-product-text {
                color: var(--gray700);
            }
            .oferta-container-main-product-symbol {
                color: var(--gray300);
            }
        .oferta-container-main-planList {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 20px; 
            width: 100%;
            max-width: 1440px;
            margin-top: 40px;
            margin-bottom: 38px;
            align-self: center;
        }
            @media only screen and (max-width: 37.5em) {
                .oferta-container-main-planList {
                    overflow-x: scroll;
                    width: 100%;
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                }
            }
            .oferta-container-main-planList::after {
                content: '';
                flex-basis: calc(25% - 23px); /* Adjust this value to match the width of items */
                pointer-events: none;
            }
            .oferta-container-main-planList > * {
                width: calc(25% - 23px); /* Adjust width of items with margin in between */
            }
        .oferta-container-main-faqList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            margin-top: 32px;
            margin-bottom: 48px;
        }
            @media only screen and (max-width: 37.5em) {
                .oferta-container-main-faqList {
                    width: 90%;
                }
            }