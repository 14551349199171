.burgerMenuItem-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 5px;
    border-radius: 15px;
    background-color: transparent;
    cursor: pointer;
    width: 90%;
    margin-bottom: 16px;
}
    .burgerMenuItem-container-text {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        align-items: center;
        color: var(--primary500);
        margin: 0;
        margin-left: 10px;
    }
    @media only screen and (max-width: 37.5em) { 
        .burgerMenuItem-container-text {
            font-size: 1rem;
        }
    }