.customTextInput-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
}
    .customTextInput-container-label {
        display: flex;
        flex-direction: row;
    }
        .customTextInput-container-label-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .customTextInput-container-label-requiered {
            color: var(--statusErrorPrimary);
            margin: 1000;
        }
    .customTextInput-container-cambiar {
        display: flex;
        margin-left: auto;
        color: var(--primary500);
        cursor: pointer;
    }
    .customTextInput-container-inputContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        border: 1px solid var(--gray200);
        border-radius: 15px;
    }
        .customTextInput-container-inputContainer-preValue {
            font-style: normal;
            font-weight: 300;
            font-size: 1.25rem;
            line-height: 24px;
            color: var(--gray700);
            margin-right: -5px;
        }
        .customTextInput-container-inputContainer-verified {
            font-style: normal;
            font-weight: normal;
            font-size: 1.25rem;
            color: #27AE60;
            margin: 0;
        }
        .customTextInput-container-inputContainer-cambiar {
            font-style: normal;
            font-weight: normal;
            font-size: 1.25rem;
            color: var(--primary500);
            margin: 0;
            cursor: pointer;
        }
            .customTextInput-container-inputContainer-cambiar:active {
                transform: scale(0.9);
            }
        .customTextInput-container-inputContainer-iconContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: auto;
        }
        .customTextInput-container-inputContainer-input {
            border: none;
            background-color: transparent;
            color: var(--gray700);
            width: 100%;
            padding: 11px;
            font-size: 14px;
            line-height: 16px;
        }
            .customTextInput-container-inputContainer-input:focus {
                outline: none;
            }
            .customTextInput-container-inputContainer-input::placeholder {
                font-family: Lato, sans-serif;
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;             
                color: var(--gray300);
            }
            .customTextInput-container-inputContainer-input-adminLogin::placeholder{
                font-family: Lato, sans-serif;
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;                
                color: var(--gray300);
            }
            .customTextInput-container-inputContainer-input:placeholder-shown {
                background-color: transparent;
            }
    .customTextInput-container-line {
        height: 1px;
        width: 100%;
        background-color: var(--gray200);
    }
    .customTextInput-container-errorMessage {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 24px;
        color: #FE1039;
        margin: 0;
        margin-bottom: -24px;
    }
    .customTextInput-container-underText {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: -14px;
    }
        .customTextInput-container-underText-text {
            font-style: normal;
            font-weight: 300;
            font-size: 0.8125rem;
            color: var(--primary500);
            margin: 0;
        }

        input[type='date']{
            text-transform: uppercase;
        }

.customTextInput-container-loader {
    width: 18px;
    height: 18px;
    border: 2px solid var(--primary500);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-left: auto;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 