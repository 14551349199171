.atencionAlert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .atencionAlert-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .atencionAlert-container-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .atencionAlert-container-data-title {
            color: var(--primary500);
            margin-bottom: 24px;
        }
        .atencionAlert-container-data-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
        }
            .atencionAlert-container-data-section-text {
                text-align: center;
                color: var(--gray500);
            }
            .atencionAlert-container-data-section-data {
                text-align: center;
                color: var(--primary500);
                cursor: pointer;
            }
    .atencionAlert-container-botton {
        width: 30%;
    }
        @media only screen and (max-width: 37.5em) {
            .atencionAlert-container-botton {
                width: 50%;
            }
        }