.perfilHome-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    @media only screen and (max-width: 37.5em) {
        .perfilHome-container {
            width: 95%;
            flex-direction: column;
        }}
    .perfilHome-container-main {
        display: flex;
        flex-direction: column;
        width: 94%;
        margin: 0 auto;
    }
        @media only screen and (max-width: 37.5em) {
            .perfilHome-container-main {
                width: 91%;
                margin-left: 25px;
            }}
        .perfilHome-container-main-superTitulo {
            margin: 30px 0;
        }
        .perfilHome-container-main-titulo {
            color: var(--gray700);
            margin-bottom: 4px;
        }
        .perfilHome-container-main-list {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 97.5%;
        }
            @media only screen and (max-width: 37.5em) {
                .perfilHome-container-main-list {
                    flex-direction: column;
                    align-items: center;
                    flex-wrap: nowrap;
                    width: 100%;
                }}
            .perfilHome-container-main-list-specialInput {
                display: flex;   
                flex-direction: column;
                justify-content: flex-start;
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list-specialInput {
                        width: 100%;
                    }}
                .perfilHome-container-main-list-specialInput-label {
                    display: flex;
                    flex-direction: row;
                }
                    .perfilHome-container-main-list-specialInput-label-text {
                        color: var(--gray500);
                    }
                    .perfilHome-container-main-list-specialInput-label-requiered {
                        color: var(--statusErrorPrimary);
                    }
                .perfilHome-container-main-list-specialInput-main {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    position: relative;
                }
                    .perfilHome-container-main-list-specialInput-main-input {
                        border: none;
                        background-color: transparent;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--black);
                        width: 100%;
                        font-family: Lato, sans-serif;
                        padding: 10px;
                        border-bottom: 1px solid var(--gray300);
                        background-color: var(--gray100);
                    }
                    .perfilHome-container-main-list-specialInput-main-input:focus {
                        outline: none;
                    }
                    .perfilHome-container-main-list-specialInput-main-input::placeholder {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1rem;
                        line-height: 1.5rem;                
                        color: var(--gray300);
                        font-family: Lato, sans-serif;
                    }
                    .perfilHome-container-main-list-specialInput-main-line {
                        color: var(--gray300);
                        margin: 0 10px;
                    }
                    .perfilHome-container-main-list-specialInput-main-status {
                        position: absolute;
                        right: 0;
                    }
            .perfilHome-container-main-list-list {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 31%;
            }
                .perfilHome-container-main-list-list > * {
                    width: 31%;
                }
            .perfilHome-container-main-list-invis {
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list-invis {
                        display: none;
                    }}
            .perfilHome-container-main-list > * {
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list > * {
                        width: 100%;
                    }}
            .perfilHome-container-main-list-doubleItem {
                width: 65.55%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list-doubleItem {
                        width: 100%;
                    }}
            .perfilHome-container-main-list-item-selectorWrapper {
                display: flex;
                flex-direction: column;
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list-item-selectorWrapper {
                        width: 100%;
                    }}
                .perfilHome-container-main-list-item-selectorWrapper-title {
                    color: var(--gray500);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: flex;
                    flex-direction: row;
                }
                .perfilHome-container-main-list-item-selectorWrapper-title ul {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 24px;
                    color: red;
                    margin: 0;
                    padding: 0;
                }
            .perfilHome-container-main-list-innerList {
                display: flex;
                flex-direction: row;
                width: 103%;
            }
                @media only screen and (max-width: 37.5em) {
                    .perfilHome-container-main-list-innerList {
                        width: 105%;
                    }}