.siniestroAlert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .siniestroAlert-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .siniestroAlert-container-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .siniestroAlert-container-data-img {
            margin-bottom: 24px;
        }
        .siniestroAlert-container-data-title {
            text-align: center;
            color: var(--gray700);
            margin-bottom: 24px;
        }
        .siniestroAlert-container-data-text {
            text-align: center;
            color: var(--gray300);
            margin-bottom: 24px;
        }
        .siniestroAlert-container-data-number {
            text-align: center;
            color: var(--primary500);
            margin-bottom: 24px;
        }
        .siniestroAlert-container-data-cel {
            text-align: center;
            color: var(--primary500);
            margin-bottom: 24px;
            cursor: pointer;
        }
    .siniestroAlert-container-botton {
        width: 30%;
    }
        @media only screen and (max-width: 37.5em) {
            .siniestroAlert-container-botton {
                width: 50%;
            }
        }