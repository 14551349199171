.formFieldBonificacionesCarfacil-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .formFieldBonificacionesCarfacil-container-main {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 50%;
    }
        .formFieldBonificacionesCarfacil-container-main-selectorWrapper {
            display: flex;
            flex-direction: column;
            width: 48.5%;
        }
            .formFieldBonificacionesCarfacil-container-main-selectorWrapper-title {
                color: var(--gray500);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                flex-direction: row;
            }
            .formFieldBonificacionesCarfacil-container-main-selectorWrapper-title ul {
                font-style: normal;
                font-weight: bold;
                font-size: 1.25rem;
                line-height: 24px;
                color: var(--statusErrorPrimary);
                margin: 0;
                padding: 0;
            }
    .formFieldBonificacionesCarfacil-container-button {
        display: flex;
        width: 20%;
        position: absolute;
        bottom: 20px;
        right: 0;
    }