.checkoutDetailsProduct-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -20px;
}
    @media only screen and (max-width: 37.5em) {
        .checkoutDetailsProduct-container {
            display: none;
        }}
    .checkoutDetailsProduct-container-period {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary200); 
        border-radius: 0px 0px 10px 10px;
        padding: 5px;
        width: fit-content;
    }
        .checkoutDetailsProduct-container-period-text {
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            text-align: center;
            color: var(--primary700);
            margin: 0;
        }
    .checkoutDetailsProduct-container-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 30px;
    }
        .checkoutDetailsProduct-container-data-texts {
            display: flex;
            flex-direction: column;
        }
            .checkoutDetailsProduct-container-data-texts-title {
                color: var(--gray400);
                margin-bottom: 8px;
            }
            .checkoutDetailsProduct-container-data-texts-plan {
                color: var(--gray700);
                margin-bottom: 32px;
            }
            .checkoutDetailsProduct-container-data-texts-insurer {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
                .checkoutDetailsProduct-container-data-texts-insurer-text {
                    color: var(--gray500);
                    margin-right: 8px;
                }
                .checkoutDetailsProduct-container-data-texts-insurer-image {
                }
        .checkoutDetailsProduct-container-data-image {
            width: 50%;
            height: auto;
        }