.PaymentMethodCreditCard-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: var(--primary500);
    border-radius: 25px;
    padding: 10px 0;
    transition: all 0.6s ease;
    margin-left: auto;
    margin-right: auto;
}
    @media only screen and (max-width: 37.5em) {
        .PaymentMethodCreditCard-container {
            background: var(--primary500);
            width: 90%;
        }}
    .PaymentMethodCreditCard-container-text {
        font-style: normal;
        font-weight: normal;
        font-size: 1.0625rem;
        line-height: 24px;
        color: var(--white);
        margin: 0;
        margin-left: 10px;
    }
    .PaymentMethodCreditCard-containerStart {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 25px;
        padding: 10px 0;
        animation-name: backgroundColorPalette;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-timing-function: ease;      
        background: linear-gradient(to right, var(--primary700) 50%, var(--primary500) 10%) left;
        background-size: 200%;
    }
        @media only screen and (max-width: 37.5em) {
            .PaymentMethodCreditCard-containerStart {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-radius: 25px;
                padding: 10px 0;
                animation-name: backgroundColorPalette;
                animation-duration: 2s;
                animation-iteration-count: infinite;
                animation-direction: normal;
                animation-timing-function: ease;      
                background: linear-gradient(to right, var(--primary700) 50%, var(--primary500) 10%) left;
                background-size: 200%;
            }}
    @keyframes backgroundColorPalette {
        0% {background-position: right;}
        100% {background-position: left;}
    }
    .PaymentMethodCreditCard-containerSuccess {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #27AE60;
        width: 50px !important;
        height: 50px !important;
        border-radius: 50%; 
        transition: all 0.6s ease;
    }
    .PaymentMethodCreditCard-containerPending {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: var(--statusWarningPrimary);
        width: 50px !important;
        height: 50px !important;
        border-radius: 50%; 
        transition: all 0.6s ease;
    }
    .PaymentMethodCreditCard-containerError {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #ff004c;
        width: 50px !important;
        height: 50px !important;
        border-radius: 50%; 
        transition: all 0.6s ease;
    }
.cvvCheckout-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
    @media only screen and (max-width: 37.5em) {
        .cvvCheckout-container {
            width: 90%;
        }}
    .cvvCheckout-container-close {
        display: flex;
        margin-left: auto;
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .cvvCheckout-container-buttonWrapper {
        margin-top: 20px;
        width: 100%;
    }
    .cvvCheckout-container-inputWrapper {
        width: 100%;
        margin-top: 20px;
    }