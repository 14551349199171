.formFieldQuoteCarfacil-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -30px;
}
    .formFieldQuoteCarfacil-container-section {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
        .formFieldQuoteCarfacil-container-section-title {
            margin-top: 30px;
        }
        .formFieldQuoteCarfacil-container-section-inputs {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
            .formFieldQuoteCarfacil-container-section-inputs > *{
                width: 30% !important;
                margin: 12px 0;
                margin-right: 10px;
            }
            .formFieldQuoteCarfacil-container-section-inputs-ceroKm {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100% !important;
            }
                .formFieldQuoteCarfacil-container-section-inputs-ceroKm-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20px;
                    width: 20px;
                    border: 2px solid var(--primary500);
                    border-radius: 5px;
                    margin-right: 5px;
                    cursor: pointer;
                    overflow: hidden;
                }
                    .formFieldQuoteCarfacil-container-section-inputs-ceroKm-box-inner {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 20px;
                        width: 20px;
                        background-color: var(--primary500);
                        transition: all 0.6s ease;
                    }
                .formFieldQuoteCarfacil-container-section-inputs-ceroKm-text {
                    color: var(--primary500);
                }
    .formFieldQuoteCarfacil-container-button {
        width: 12.5%;
        margin-left: auto;
    }