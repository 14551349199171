.offerPlan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: var(--white);
}
    .offerPlan-container-title {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px 25px 0 0;
        width: 100%;
        height: 60px;
    }
        .offerPlan-container-title-text {
            color: var(--gray700);
            width: 80%;
            text-align: center;
        }
    .offerPlan-container-price {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
    }
        .offerPlan-container-price-unit {
            color: var(--gray400);
            margin-bottom: 5px;
        }
        .offerPlan-container-price-price {
            color: var(--gray700);
        }
        .offerPlan-container-price-per {
            color: var(--gray400);
            margin-bottom: 5px;
        }
    .offerPlan-container-period {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 8px 0;
    }
        .offerPlan-container-period-text {
            color: var(--gray600);
        }
    .offerPlan-container-line {
        width: 100%;
        height: 1px;
        background-color: var(--gray200);
    }
    .offerPlan-container-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 8px 0;
    }
        .offerPlan-container-details-text {
            color: var(--gray400);
        }
    .offerPlan-container-boton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 45%;
        margin-bottom: 20px;
    }