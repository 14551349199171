.selectorSearcher-container {
    width: 100%;
}
    @media only screen and (max-width: 37.5em) {
        .selectorSearcher-container {
            margin-left: auto;
            margin-right: auto;
        }}
    .selectorSearcher-container-label {
        display: flex;
        flex-direction: row;
    }
        .selectorSearcher-container-label-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .selectorSearcher-container-label-requiered {
            color: var(--statusErrorPrimary);
            margin: 1000;
        }
    .selectorSearcher-container-selector {
        display: flex;
        transition: height 0.6s ease-in;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: var(--gray100);
        border-radius: 15px;
    }
        .selectorSearcher-container-selector-loader {
            width: 18px;
            height: 18px;
            border: 3px solid var(--primary500);
            margin: 10px;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }
        .selectorSearcher-container-selector-placeholder {
            font-size: 14px;
            line-height: 16px;
            padding-left: 15px;
            color: var(--gray300);
        }
        .selectorSearcher-container-selector-value {
            display: flex;
            align-items: center;
            padding-left: 15px;
            border: 0;
            background: transparent;
            outline: 0;
            font-size: 14px;
            line-height: 16px;
            width: 92.5%;
        }
        .selectorSearcher-container-selector-input {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: var(--black);
            margin: 0;
            padding-left: 15px;
            border: 0;
            background: transparent;
            outline: 0;
            width: 92.5%;
        }
            .selectorSearcher-container-selector-input::placeholder {
                font-family: Lato, sans-serif;
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;                
                color: var(--gray300);
            }
        .selectorSearcher-container-selector-inputDisabled {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: var(--gray300);
            margin: 0;
            padding-left: 15px;
            border: 0;
            background: transparent;
            outline: 0;
            width: 92.5%;
            font-family: Lato, sans-serif;
        }
            .selectorSearcher-container-selector-inputDisabled::placeholder {
                font-family: Lato, sans-serif;
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;                
                color: var(--gray300);
            }
        .selectorSearcher-container-selector-inputShake {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: var(--statusErrorPrimary);
            margin: 0;
            padding-left: 15px;
            border: 0;
            background: transparent;
            outline: 0;
            width: 92.5%;
        }
            .selectorSearcher-container-selector-inputShake::placeholder {
                font-family: Lato, sans-serif;
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: var(--statusErrorPrimary);
            }
        .selectorSearcher-container-selector-lupa {
            display: flex;
            border-radius: 0px 25px 25px 0px;
            align-items: center;
            justify-content: center;
            padding: 6.5px;
            width: 7.5%;
            transition: all ease 0.5s;
        }
    .selectorSearcher-container-list {
        transition: all 0.6s ease-in;
        overflow-y: scroll;
        background: var(--white);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        border-radius: 0px 0px 25px 25px; 
        position: absolute;
        z-index: 10;
    }
        .selectorSearcher-container-list-popularText {
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375rem;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: var(--gray200);
            margin: 0;
            padding-top: 10px;
            padding-left: 25px;
        }
        .selectorSearcher-container-list-line {
            height: 1px;
            background-color: var(--gray200);
            width: 95%;
            margin-left: 25px;
        }
        .selectorSearcher-container-list-listItemText {
            display: flex;
            align-items: center;
            color: var(--gray700);
            cursor: pointer;
            padding: 10px 0;
            padding-left: 15px;
        }
            .selectorSearcher-container-list-listItemText:hover {
                background-color:var(--gray100);
            }
            .selectorSearcher-container-list-listItemText:active {
                background-color:var(--primary200);
                color: var(--primary700)
            }

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 