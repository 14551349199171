.detailsPolicy-container {
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}
  .detailsPolicy-container-main {
      display: flex;
      flex-direction: row;
      width: 94%;
      align-self: center;
      justify-content: space-between;
  }
      @media only screen and (max-width: 37.5em) {
          .detailsPolicy-container-main {
            width: 92%;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 20px;
          }
      }
      .detailsPolicy-container-main-left {
        display: flex; 
        flex-direction: column;
        width: 30%;
        margin-bottom: 20px;
      }
        @media only screen and (max-width: 37.5em) {
          .detailsPolicy-container-main-left {
            width: 100%;
            margin-bottom: 30px;
          }
        }
        .detailsPolicy-container-main-left-circulacion {
          display: flex;
          flex-direction: column;
          border-radius: 15px;
          overflow: hidden;
          width: 100%;
          box-shadow: 4px 6px 20px 0px rgba(0, 0, 0, 0.16);
          margin-bottom: 32px;
        }
          .detailsPolicy-container-main-left-circulacion-top {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--white);
            width: 100%;
            padding: 10px 0;
          }
            .detailsPolicy-container-main-left-circulacion-top-imgWrapper {
              width: 90%;
            }
              .detailsPolicy-container-main-left-circulacion-top-imgWrapper-img {
                width: 50%;
              }
          .detailsPolicy-container-main-left-circulacion-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: var(--primary500);
            padding: 10px 0;
            position: relative;
          }
            .detailsPolicy-container-main-left-circulacion-bottom-wrapper {
              display: flex;
              flex-direction: column;
              width: 90%;
            }
              .detailsPolicy-container-main-left-circulacion-bottom-wrapper-name {
                color: var(--gray100);
                margin-bottom: 4px;
              }
              .detailsPolicy-container-main-left-circulacion-bottom-wrapper-product {
                color: var(--gray200);
                margin-bottom: 4px;
              }
              .detailsPolicy-container-main-left-circulacion-bottom-wrapper-date {
                color: var(--gray200);
              }
              .detailsPolicy-container-main-left-circulacion-bottom-wrapper-button {
                width: 35%;
                position: absolute;
                bottom: 0;
                right: -10px;
              }
        .detailsPolicy-container-main-left-descargarPoliza {
          margin: auto;
          margin-top: 8px;
          margin-bottom: 32px;
        }
        .detailsPolicy-container-main-left-title {
            color: var(--gray700);
            margin-top: 30px;
            margin-bottom: 25px;
        }
        .detailsPolicy-container-main-left-subTitle {
          color: var(--gray700);
          margin-bottom: 16px;
        }
        .detailsPolicy-container-main-left-product {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 32px;
        }
          .detailsPolicy-container-main-left-product-imgWrap {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 108px;
            width: 108px;
            border-radius: 8px;
            background: var(--primary200);
          }
            .detailsPolicy-container-main-left-product-imgWrap-img {
              width: 80%;
              height: 80%;
            }
            .detailsPolicy-container-main-left-product-texts {
              display: flex;
              flex-direction: column;
              margin-left: 10px;
            }
              .detailsPolicy-container-main-left-product-texts-text {
                color: var(--gray700);
              }
          .detailsPolicy-container-main-left-product-switch {
            display: flex;
            align-items: center;
            border-radius: 100px;
            min-width: 53px;
            height: 32px;
            transition: all 0.5s ease-in;
            cursor: pointer;
            margin-bottom: auto;
            margin-left: auto;
          }
          .detailsPolicy-container-main-left-product-switch-ball {
              min-width: 24px;
              height: 24px;
              background-color: var(--white);
              border-radius: 25px;
              transition: all 0.5s ease-in;
          }
        .detailsPolicy-container-main-left-poliza {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-radius: 20px;
          border-bottom: 1px solid var(--gray100);
          background: var(--white);
          height: 64px;
          padding: 0 16px;
          width: 93%;
        }
          .detailsPolicy-container-main-left-poliza-number {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
        .detailsPolicy-container-main-left-payTitle {
          margin-top: 23px;
          color: var(--gray700);
        }
          @media only screen and (max-width: 37.5em) {
            .detailsPolicy-container-main-left-payTitle {
              margin-top: 13px;
            }
          }
        .detailsPolicy-container-main-left-pay {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-radius: 20px;
          border-bottom: 1px solid var(--gray100);
          background: var(--white);
          height: 64px;
          padding: 0 16px;
          margin-top: 16px;
          margin-bottom: 32px;
          width: 93%;
        }
          .detailsPolicy-container-main-left-pay-card {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        .detailsPolicy-container-main-left-links {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
          @media only screen and (max-width: 37.5em) {
            .detailsPolicy-container-main-left-links {
              justify-content: space-between;
            }
          }
          .detailsPolicy-container-main-left-links > * {
            width: 80px;
            height: 80px;
            margin-right: 8px;
          }
            @media only screen and (max-width: 37.5em) {
              .detailsPolicy-container-main-left-links > * {
                margin-right: 0px;
              }
            }
      .detailsPolicy-container-main-right {
        display: flex; 
        flex-direction: column;
        align-items: flex-start;
        width: 60%;
        margin-top: 30px;
      }
        @media only screen and (max-width: 37.5em) {
          .detailsPolicy-container-main-right {
            width: 100%;
          }
        }
        .detailsPolicy-container-main-right-pay {
          display: flex;
          flex-direction: column;
          width: 50%;
        }
          @media only screen and (max-width: 37.5em) {
            .detailsPolicy-container-main-right-pay {
              width: 100%;
            }
          }
          .detailsPolicy-container-main-right-pay-title {
            color: var(--gray700);
            margin-bottom: 16px; 
          }
        .detailsPolicy-container-main-right-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 21px;
        }
        .detailsPolicy-container-main-right-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
          .detailsPolicy-container-main-right-list-item {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            border-bottom: 1px solid var(--gray300);
            width: 100%;
            padding-bottom: 10px;
            margin-bottom: 22px;
            overflow: hidden;
          }
            .detailsPolicy-container-main-right-list-item-title {
              width: 50%;
              text-align: left;
            }
            .detailsPolicy-container-main-right-list-item-value {
              width: 50%;
              text-align: right;
            }
        .detailsPolicy-container-main-right-bot {
          margin-right: auto;
          width: 25%;
          margin-top: 16px;
        }
          @media only screen and (max-width: 37.5em) {
            .detailsPolicy-container-main-right-bot {
              width: 100%;
            }
          }