.paymentEfectivo-container {
    display: flex;
    border-radius: 15px;
    border-bottom: 1px solid var(--gray100);
    background: var(--white);
    align-items: center;
    justify-content: center;
    width: 100%;
}
    .paymentEfectivo-container-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        padding: 15px 0;
    }
        .paymentEfectivo-container-wrapper-card {
            display: flex;
            align-items: center;
            justify-content: center;
        }
            .paymentEfectivo-container-wrapper-card-text {
                color: var(--gray700);
                margin-left: 8px;
            }