.header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 56px;
    min-height: 56px;
    z-index: 10;
    width: 100%;
}
.header-containerFixed {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 56px;
    min-height: 56px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    transition: all 0.5s ease-in;
}
    .header-container-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 94%;
    }
        @media only screen and (max-width: 38.5em) {
            .header-container-main {
                width: 92%;
            }
        }
        .header-container-main-logo {
            height: 30px;
            cursor: pointer;
        }